@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

//Custom
section.error {
  h1 {
    font-size: 4rem;
    font-weight: 700;

    @media (max-width: 1400px) {
      font-size: 3.5rem;
    }
    @media (max-width: 500px) {
      font-size: 2.5rem;
    }
  }
}

a.btn.custom {
  padding-left: 0.20rem;

  span {
    &.spinner-border.hover {
      --bs-spinner-animation-speed: 0s !important;
      width: 0 !important;
      opacity: 0 !important;
      transition: all .2s ease-in-out;
    }
  }

  &:is(&:hover,&:focus) {
    padding-left: 0.75rem;

    span.spinner-border {
      --bs-spinner-animation-speed: 1s !important;
      width: 16px !important;
      opacity: 1 !important;
    }
  }
}

section.error-404 {
  * {
    font-family: 'Poppins', sans-serif;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9500fe;
    padding: 30px;
    box-shadow: 0 0 10px rgba(0,0,0,.12);
  }
  .outer {
    padding: 15px 25px;
    background: #fff;
    display: inline-block;
    margin-bottom: 15px !important;
  }
  h1 {
    font-size: 7rem;
    font-weight: 800;
    color: #483d90;
    margin: 0 !important;
    span.highlight {
      color: #f18b2f;
    }
  }
  h3 {
    color: #fff;
  }
  i {
    font-family: "Font Awesome 6 Pro" !important;
  }

  @media (max-width: 991px ) {
    img {
      display: none !important;
    }
  }
}